.container-fluid {
  max-width: 1650px;
  font-size: 1.6rem;
}

.main-content {
  padding: 0 150px 150px 150px;
  

  font-size: 1.8rem;
  // transition: all .4s ease;

  @include media-breakpoint-down(xs) {
    padding-left: 0;
  }


  .top-navbar {
    padding: 2.4rem 1.4rem;

    .btn-outline-secondary.dropdown-toggle {
      min-width: 325px;
      text-align: left !important;
      border-color: #ced4da;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: $secondary;
        box-shadow: none;
      }

      &::after {
        position: absolute;
        right: 6px;
        top: 12px;
      }

    }
  }
}

.second-row {
  .card {
    height: 375px;
  }
}

// .logo {
//   max-width: 140px;
// }

// .logo-login {
//   max-width: 140px;

// }

.logo-emblem {
  display: none;
  max-width: 100px;
}

.navbar {
  // box-shadow: 0 3px 6px rgba(#000000, 0.05);

  &.top-navbar {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .main-navigation {
    justify-content: center;

    .nav-link {
      font-size: 1.6rem;
      position: relative;
      display: flex;
      align-items: center;
    }

    +.nav {
      .nav-link {
        font-size: 1.6rem;
        position: relative;
        display: flex;
        align-items: center;
      }
    }

  }

  .ico-notification {
    margin-right: 1.5rem;

    i {
      color: $grey;
    }

    &::after {
      display: none;
    }

    &:hover {
      color: $primary;
    }
  }

  .notification {
    padding: 1.4rem 0;
    font-size: 1.6rem;
    width: 40rem;
    white-space: normal;
    display: flex;
    align-items: center;

    img {
      max-width: 2.5rem;
      margin-right: 2rem;
    }

    .time_ago {
      font-weight: 500;
      font-size: 1.2rem;
      color: $grey;
    }

    span {
      font-weight: 600;
      font-size: 1.6rem;
      color: $primary;
    }

    span {
      font-weight: bold;
    }

    &:hover {}
  }
}

.ico-user {
  background-color: $info;
  color: #ffffff !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 50%;

  &.ico-2x {
    width: 8rem;
    height: 8rem;
    font-size: 2.6rem;
  }

  &::after {
    display: none;
  }

  &:hover {
    background-color: darken($info, 10%);
  }

  +.dropdown-menu {
    background-color: rgba($primary, 1);

    a {
      color: #ffffff;
    }
  }
}

.dashboard-container {
  max-width: 1650px;
  margin-left: 9vw;
  margin-right: 9vw;
  margin-top: 4.5rem;
  font-size: 1.6rem;

  h1 {
    margin-bottom: 2.6rem;
  }
}

.ico-insight {
  color: $primary;
  background: rgba($primary, .2);
  border-radius: 8px;
  padding: 10px;
  width: 40px;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.2;

  &.bg-warning {
    background: rgba($primary, .2) !important;

  }

  &.rounded-circle {
    font-size: 1.6rem;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 1;
    padding: 4px;
  }

}

.insight-participants {
  .ico-insight {
    background-color: rgba($info, .2);
  }
}

.insight-primary {
  .ico-insight {
    background-color: rgba($primary, .2);
    color: $primary;

  }
}

.insight-spend,
.insight-warning {
  .ico-insight {
    background-color: rgba($warning, .2);
    color: $warning;

  }
}

.insight-pmpm,
.insight-info {
  .ico-insight {
    background-color: rgba($info, .2);
    color: $info;

  }
}

.insight-claims,
.insight-success {
  .ico-insight {
    background-color: rgba($success, .2);
    color: $success;

  }
}

.insight-danger {
  .ico-insight {
    background-color: rgba($danger, .2);
    color: $danger;

  }
}

.insight-claim-cost,
.insight-blue {
  .ico-insight {
    background-color: rgba($blue, .2);
    color: $blue;
  }

  h1,
  h2 {
    color: $blue;
  }
}

.insight-combo-drug {
  .ico-insight {
    background-color: rgba($danger, .2);
    color: $danger;
  }

  h2 {
    color: $blue;
  }
}

.member-area {
  .quick-actions {
    min-height: 16rem;
  }

  .list-group {
    border-radius: 10px;
    overflow: hidden;

    span {
      color: $dark;
    }

    .list-group-item {
      display: flex;
      align-items: center;
      padding: 2rem;

      .row {
        flex-grow: 1;
      }

      img {
        max-width: 4.5rem;
      }
    }

    &.today {
      h2 {
        color: $primary;
      }

      .list-group-item {
        border-bottom: 0;
        padding: 1rem 3rem 4.5rem;
      }
    }
  }

  .card {
    .card-body {
      span {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }

    .card-right-col {
      div {
        font-weight: 500;
      }
    }
  }
}

.events-container {
  .event-image {
    img {
      max-width: 350px;
    }
  }
}

.closed {
  opacity: 0.6;
}

form {
  font-size: 1.8rem;
}

.form-control,
.custom-select {
  font-size: 1.8rem;
}

.custom-select {
  padding-right: 2.75rem;
}

.form-check {
  margin-bottom: 1rem;
}

.input-group-text {
  font-size: 1.6rem;
}

.custom-control {
  padding-left: 2.5rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  height: 2rem;
  width: 3.5rem; // it was 1.75rem before. Sliding way is longer than before.
  pointer-events: all;
  border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(2rem - 4px); // it was calc(1rem - 4px) before. Oval is bigger than before.
  height: calc(2rem - 4px); // it was calc(1rem - 4px) before. Oval is bigger than before.
  background-color: #adb5bd;
  border-radius: 2rem; //   it was 0.5rem before. Oval is bigger than before.
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(1.5rem);
}

// .custom-control-label {
//   &::before {
//     width: 2rem !important;
//     height: 2rem !important;
//     border-radius: 0.3rem !important;
//     left: -2.7rem;
//   }

//   &::after {
//     width: 2rem;
//     height: 2rem;
//     left: -2.7rem;
//   }
// }

// .custom-radio {
//   .custom-control-label {
//     &::before {
//       border-radius: 50% !important;
//     }
//   }
// }




.statement-months-list {
  >a {
    padding-left: 35px;
    position: relative;
    padding-bottom: 2rem;

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      left: 6px;
      top: 5px;
      background-color: $green;
    }

    &.active {
      background-color: transparent;
      color: $dark;

      &::before {
        background-color: $danger;
      }
    }
  }
}

.forms-table {

  th,
  td {
    padding-top: 15px;
    padding-bottom: 15px;

    &:last-child {
      text-align: right;
      padding-right: 2rem;

      a {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
}

.legend {
  font-weight: normal;
  font-size: 1.4rem;

  &::before {
    content: '';
    background: $info;
    border-radius: 2px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 5px;
  }
}

.legend-plan-pay,
.legend-prev-month,
.legend-info {
  @extend .legend;

  &::before {
    background: $info;
  }
}

.legend-current-month {
  @extend .legend;

  &::before {
    background: #B3D5F7;
  }
}

.legend-co-pay {
  @extend .legend;

  &::before {
    background: $success;
  }
}

.legend-brand {
  @extend .legend;


  &::before {
    @extend .bg-brand-bar;
  }
}

.legend-speciality,
.legend-success {
  @extend .legend;

  &::before {
    background: $success;
  }
}

.legend-generic {
  @extend .legend;

  &::before {
    @extend .bg-genric-bar;

  }
}

.group-stage {
  width: 350px;
}


.summary-third-row {
  a {
    font-size: 1.6rem;
  }

  .card {
    font-size: 1.6rem;
  }

  .card-body {
    font-weight: bold;
    color: $blue;
  }
}

table {
  th {

    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  td {
    &:first-child {
      text-align: left;
      padding-left: 2rem;
    }

    text-align: center;
    font-weight: 600;
  }
}

.page-link {
  padding: .7rem 1.3rem;
}

.login-card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.text-female {
  color: #6542C3;
}

.insights-container {
  .small {
    font-size: 70%;
  }
}


.details-panel {
  a {
    text-decoration: underline;
  }
}

.tooltip-inner {
  border: 1px solid #CECECE;
  padding: 1rem 2rem;
}

.bg-physicians-bar {
  background-color: #C1DEF0 !important;
}

.bg-brand-bar {
  background-color: #6542C3 !important;
}

.bg-speciality-bar {
  background-color: $success !important;
}

.bg-genric-bar {
  background-color: #BD6FF7 !important;
}

.table-collapse {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
}

tr {
  &[data-toggle="collapse"] {
    cursor: pointer;

    &:not(.collapsed) {
      .fa-chevron-right {
        transform: rotate(90deg);
      }
    }
  }
}

.bg-card-header {
  background-color: #E8F4FF !important;
}

.border-dashed {
  border: 1px dashed #707070;
  // border-radius: 1.4rem;
}


.nav-settings {
  .nav-link {
    color: $body-color;
  }
}


@media print {}

html.report,
body.report {
  background-color: #ffffff;
  font-size: 62.5%;
}

.report {
  .card {
    .card-header {
      font-size:2.4rem
    }
  }
  ul{
    font-size: 2.4rem;
  }
  h1 {
    font-weight: bold;
    font-size: 4.2rem;
    color: $dark;
  }

  h2 {
    font-weight: normal;
    font-size: 3.4rem;
    color: $dark;
  }
  h3 {
    
    font-size: 3.0rem;
  }
  p {
    font-size: 2.0rem;
  }
  .main-content {
    padding: 1px 0px 0 !important;
    position: relative;
  }

  .report {

    .top-line,
    .left-line {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $blue;
    }

    .top-line {
      height: 0.65cm;
      width: 50vw;

      &::after {
        content: "";
        position: absolute;
        background-color: #ffffff;
        right: -10px;
        top: 0;
        height: 35px;
        width: 0.65cm;
        transform: rotate(35deg);
      }
    }

    .left-line {
      width: 0.65cm;
      height: 50vw;

      &::after {
        content: "";
        position: absolute;
        background-color: #ffffff;
        right: 0;
        bottom: -10px;
        height: 0.65cm;
        width: 35px;
        transform: rotate(35deg);
      }
    }

    .report-title {
      margin-top: 7cm;
    }

    .page-body {
      height: 35.96cm;
      padding: 0 2cm;
      border: 1px solid $blue;
    }

    .cover {
      height: 38.95cm;
      padding: 0 2cm;
    }

    .report-header {
      background-color: $blue;
      height: 3cm;
      width: 100%;
      color: #ffffff;
      padding: 25px 1.5cm;
    }

    .report-footer {
      background-color: $blue;
      height: 3cm;
      width: 100%;
      margin-bottom: 0%;
      page-break-after: always;
    }

    .presented-by {
      margin-top: 17cm;
    }

    .cover {

      .cover_logo {
        max-width: 300px;
        margin: 100px auto;
      }
    }
  }
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.5; 
  margin-left: 3px;
  height: 30px;
}

.navbar.top-navbar {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  // margin-left: 100px;
}
.print-card {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.20);
  border: none;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  font-size: 2.4rem;

  .card-body {
    overflow: hidden;
  }

  .ico-insight {
    width: 50px;
  }
}