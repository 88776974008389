.blockPage {
  position: fixed;
  z-index: 9899;

  width: 100vw;
  min-height: 100vh;
  max-height: auto;
  visibility: hidden;
  background-color: rgba(192, 169, 169, 0);
  transition: background-color 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.blockPage--visible {
  background-color: rgba(0, 0, 0, 0.6);
  visibility: visible;
}

// lds-ellipsis styles in App.scss

.loaderContainer {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100%;

  visibility: hidden;
  background-color: transparent;
  transition: visibility 0.2s ease-in-out;
}

.loaderContainer--visible {
  visibility: visible;
}
