/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('./fonts/fa-light-300.woff2') format('woff2'),
    url('./fonts/fa-light-300.woff') format('woff');
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
