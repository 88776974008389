.carouselContainer {
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  background-color: #022163;
}
.eventsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 95vw;
  margin: 4rem auto;
  flex-wrap: wrap;
  align-items: stretch;
}

.eventsContainerConditional {
  display: grid;
  width: 87vw;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 4rem auto;
  align-items: stretch;
}


.cardBody {
  flex-basis: 100%;
  background-color: #fff;
  padding: 2rem;
}
img {
  width: 100%;
}

p,
h5 {
  color: #000;
}
.googleWidth>button{
  width: 100%;
  background-color: rgb(0,  105, 217) !important;
  color: #fff !important;
}
// p {
//   text-align: left;
// }

h5 {
  font-size: 1.5rem;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding: 2rem;
}


.white-text {
  width: 90%;
  margin: 10rem auto;
  p {
    color: #fff;
    font-size: 2rem;
  }
}
@media (min-width: 780px) {
.cardBody {
  flex-basis: 100%;
  background-color: #fff;
  padding: 3rem;
}
.black-text {
  width: 80%;
  margin: 10rem auto;
  p {
    color:black;
    font-size: 2rem;
  }
}
.white-text {
  width: 80%;
  margin: 10rem auto;
}
  .eventsContainer {
    gap: 2rem;
    width: 75vw;
  }

  .eventsContainerConditional {
    gap: 2rem;
    width: 50vw;
    display: grid;
  }
  
  .carouselContainer {
    padding: 4rem;
  }
}

@media only screen and (min-width: 600px) {
  .eventsContainer {
    grid-template-columns: 1fr 1fr;
  }
  .eventsContainerConditional {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1024px) {
  .eventsContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .eventsContainerConditional {
    grid-template-columns: 1fr 1fr;
  }
}
