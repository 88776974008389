@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Light.woff2') format('woff2'),
    url('./fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
