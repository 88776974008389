.portfolio {
  .dynamic__item {
    padding: 5px;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    div.cta {
      display: grid;
      grid-template-columns: auto auto auto auto;
      column-gap: 5px;
    }

    @media (max-width: 432px) {
      div.cta {
        column-gap: 0px;
      }
    }
  }

  // .d-flex.itemContainer.itemContainerDetail.itemDetailContainer.justify-content-around {
  //   margin: 0 !important;
  // }
}
