* {
  margin: 0;
  padding: 0;
}
.page1 {
  background-color: white;
}
.body1 {
  text-align: center;
}
.notification-link{
cursor: pointer;
color: blue;
}
.wrapper {
  width: 1170px;
  margin: 0 auto;
}
header {
  height: auto;
  background: #101f3e;
  width: 100%;
  z-index: 10;
  position: relative;
}
.logo {
  width: 30%;
  float: left;
  line-height: 100px;
}
.logo a {
  text-decoration: none;
  font-size: 30px;
  font-family: poppins;
  color: #fff;
  letter-spacing: 5px;
  // margin-left: -500px;
}


.font-class{

  p b{
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7 !important;
    font-size: 20px !important;


  }

}

.video_main_page {
  max-width: 85%;
}

.featured_video_adjustment{
position: relative;
  top: 16%;
}

.play_button {
  font-size: 60px;
  position: absolute;
  color: white;
  left: 48%;
  top: 39%;
  z-index: 1200;
}

.mute_button {
  font-size: 29px;
  position: absolute;
  top: 89%;
  left: 10%;
  color: white !important;
  z-index: 1200;
  cursor: pointer;
}

.MsoNormal{
 span{
  // font-size: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  line-height: 1.7 !important;
}
b span{
  // font-size: 4.45rem !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  line-height: 1.7!important;

}
i span{
  // font-size: 20px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  line-height: 1.7!important;
  font-weight: bold;

}
}

.MsoListParagraphCxSpFirst{
  span{
    // font-size: 20px !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7!important;
  }
}
.MsoListParagraphCxSpMiddle{
  span{
    // font-size: 20px !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7!important;
  }
}

.MsoListParagraphCxSpLast{
  span{
    // font-size: 20px !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7!important;
  }
}

.gmail-{
  span{
    // font-size: 20px !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7!important;
  }
  i{
    // font-size: 20px !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    line-height: 1.7!important;
  }

}

nav1 {
  float: right;
  line-height: 100px;
  margin-right: -140px;
}
nav1 a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bolder;
  margin: 20px;
  color: #fff;
}
nav1 a:hover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}
nav2 {
  line-height: 100px;
  color: Black;
}
nav2 a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin: 20px;
  color: black;
}

nav2 a:hover {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  text-decoration: none;
  transition: 0.3s;
}

.content-area {
  width: 100%;
  position: relative;
  top: 450px;
  background: #ebebeb;
  height: 1500px;
}
// .modal-content{
//   height: 300px;
// }
// .modal-body{
//   height: 250px;
// }
.content-area h2 {
  font-family: poppins;
  letter-spacing: 4px;
  padding-top: 30px;
  font-size: 40px;
  margin: 0;
}
.content-area p {
  padding: 2% 0px;
  font-family: poppins;
  line-height: 30px;
}

.caret {
  font-family: Arial, Helvetica, sans-serif;
  font-stretch: semi-condensed;
  font-size: 20px;
  font-weight: bolder;
  color: #101f3e;
  margin: 15px;
}
.caretnew {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bolder;
  color: black;
  margin: 10px;
}
.caretnew a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: black;
  margin: 10px;
}

.caretnew a:hover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: black;
  margin: 10px;
}

.fa-circle:before {
  margin-right: 15px;
}

h2.header1 {
  font-weight: bolder;
  padding-top: 20px;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-stretch: semi-condensed;
  color: white;
}

h2.seperator {
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h2.seperator span {
  background: #fff;
  padding: 0 10px;
}

h2.header {
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #101f3e;
}
h4.heading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: black;
}
h4.options {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  margin-left: 70px;
  padding-top: 15px;
}
h4.categories {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  margin-left: 110px;
  padding-top: 20px;
  margin-bottom: 10px;
}
h6.title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 9px;

  color: black;
}
h6.title1 {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  color: white;
}
.card-img-partner {
  width: 50%;
  height: 20vh;
  margin-left: 10px;
}
.card-text-white {
  margin-bottom: 20px;
}

p.timeline-Tweet-text {
  font-size: 16px;
  line-height: 14px;
  font-weight: 300;
}

.TweetAuthor {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  margin-bottom: 20px;
}

.carousel-control-prev {
  margin-left: 160px;
  margin-bottom: 30px;
}
.carousel-control-next {
  margin-right: 160px;
  margin-bottom: 30px;
}

.list-group-item {
  background-color: transparent;
}

.btn.btn-dark {
  padding: 60px;
}


// .btn {
//   background-color: DodgerBlue;
//   border: none;
//   color: white;
//   padding: 12px 16px;
//   font-size: 16px;
//   cursor: pointer;
// }

/* Darker background on mouse-over */
// .btn:hover {
//   background-color: RoyalBlue;
// }
// .raffle {
//   .ant-popover-buttons {
//    display: none !important
//   }
// }
#raffle .ant-popover-buttons{
  display: none!important;

}
.sold_out {
  color: #fff;
  left: -140%;
    bottom: 85%;
  display: block;
  text-align: center;
  position: relative;
  text-decoration: none;
  letter-spacing: .06em;
  background-color: #A00;
  padding: 0.5em 2em 0.4em 2em;
  text-shadow: 0 0 0.75em #444;
  border: 2px rgba(255, 255, 255, 0.7) dashed;
  font: bold 16px/1.2em Arial, Sans-Serif;
  -webkit-text-shadow: 0 0 0.75em #444;
  -webkit-transform: rotate(
-45deg) scale(0.75, 1);
  z-index: 10;
}



hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 40px;
  margin-left: 40px;
  color: #247ca7;
  border-top: 0.7px solid rgba(0, 0, 0, 0);
}

hr.auctions {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 40px;
  margin-left: 40px;
  color: black;
  border-top: 0.7px solid rgba(0, 0, 0, 0);
}

.btn.btn-secondary.btn-circle1 {
  margin: 3px;
  width: 17px;
  height: 17px;
  padding: 0px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  background-color: white;
}
.fa.fal.fa {
  color: black;
}
.border-bottoms {
  border-bottom: 2px solid #ddd !important;
}
.border-right {
  border-right: 2px solid #ddd !important;
}
.ul-alignment {
  text-align: justify;
  margin-left: 45%;
}
.sorting-Asc-desc-position{
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
}
.borders {
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  padding-top: 20px;
}
.border-all-side {
  border-bottom: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
}
.border-bottom-ul {
  border-bottom: 2px solid #ddd;
}
.border-top-ul {
  border-top: 2px solid #ddd;
}
.border-bottom {
  border-bottom: 2px solid #ddd !important;
}

.page-content {
  min-height: calc(100vh - 390px);
}

.socialbtn {
  width: 100%;
  padding: 10px;
  border-radius: 0px;
  margin: 5px 0;
  display: inline-block;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
}

.socialbtn:hover {
  opacity: 0.9;
  text-decoration: none;
  color: white;
}

.iconsbtn {
  width: 100%;
  padding: 3px;
  border-radius: 0px;
  margin: 5px 0;
  display: inline-block;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
}

.iconsbtn:hover {
  opacity: 0.9;
  text-decoration: none;
  color: white;
}

.facebook {
  background-color: #3b5998;
  border-radius: 0px;
  color: white;
}
.g {
  background-color: #dd4b39;
  border-radius: 0px;
  color: white;
}

.fb {
  background-color: #3b579d;
  margin-left: -10px;
  color: white;
}

.twitter {
  background-color: #55acee;
  color: white;
}

.google {
  background-color: #dd4b39;
  margin-left: -10px;
  color: white;
}

.account {
  background-color: black;
  color: white;
}
.btn-close {
  color: black;
  font-size: 12px;
}
.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 35px;
  margin-top: 80px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}
.form-check-input:checked {
  background-color: grey;
  border-color: black;
}

form input[type="radio"],
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-top: 3px;
  background-color: transparent;
}

// .container,
// .container-sm,
// .container-md,
// .container-lg,
// .container-xl {
//   text-align: left;
//   margin-left: 130px;
// }

// .img-box {
//   margin-left: 50px;
// }

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 43px;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  background-color: white;
}
.form-group {
  box-shadow: 0px 0px 10px #cecece;
}
.img-responsive {
  width: 105%;
  height: 85%;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 130px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #101f3e;
  width: 240%;
  margin-bottom: 10px;
  // margin-left: 0px;
}
.dropdown-content.lists{
 margin-left: -130px;
}
.dropdown-content a.bar {
  // color: white;
  text-decoration: none;
  display: block;
  border-bottom: white 1px solid;
  margin: 0px;
  width: 100%;
  line-height: 3;
  
}

.header_font{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dropdown-content a.bar:hover {
  
  // background-color: white;
  color: white;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  padding-right: 34px;
  margin-top: -18px;
}
.ant-calendar-picker-input.ant-input {
  margin-left: 3px;
  height: 45px;
  margin-left: -20px;
  margin-top: -10px;
  margin-bottom: 7px;
}
.labeltext {
  margin-right: 77%;
}
.labeltext1 {
  margin-right: 80%;
}
.modal-header {
  color: white;
  margin-top: -30px;
  margin-bottom: -20px;
  border-bottom: 0px;
}
.close {
  float: right;
  font-size: 26px;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  margin-bottom: -13px !important;
}
.ant-popover-placement-top {
  z-index: 99999;
}

.carouselstyle {
  max-width: 980px;
  min-width: 150px;
  background: transparent;
  border: none;
}

.ant-select-selection--single {
  height: 35px;
}
/*Basit*/
.carousel-indicators {
  margin: -40px;
  bottom: 18px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 30px;
  height: 30px;
  position: relative;
  top: 50px;
}
.inner-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}
.header1 {
  margin-left: 0 !important;
  padding-top: 0 !important;
}

.dashboard_car {
  background: #dbb265;
  min-height: 500px;
  padding-top: 30px;
  padding-bottom: 40px;
}
.main-slide-image {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
}
img.mt-4.mb-5.bg-white.rounded {
  padding: 10px 0 !important;
  margin: 0;
}
.slide-image-overflow {
  height: 390px;
  overflow: hidden;
}
a.btn.btn.py-2.px-5 {
  background-color: rgb(219, 178, 101);
}
.custom-select {
  width: 100% !important;
  margin-left: 0 !important;
}
.select-dropdown {
  width: 100%;
}
.input-group-prepend .col-md-12 {
  padding: 0 !important;
}
.myitem-portfolio-heading {
  margin-bottom: 0 !important;
}
.myitem-content-body {
  margin-top: 0 !important;
}
.main-myitems {
  padding: 30px;
}
.profile-btn-last {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/*End-basit*/
.carousel-indicators li {
  box-sizing: content-box;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: white;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.9;
  transition: opacity 0.6s ease;
  border-radius: 100%;
}
.carousel-indicators .active {
  opacity: 1;
  background-color: black;
}

header {
  height: 100px;
  background: #101f3e;
  width: 100%;
  z-index: 10;
  position: relative;
  line-height: 30px;
  text-align: center;
}
.brand {
  width: 75px;
  float: left;
  line-height: 100px;
  margin: 0 0 0 5%;
}

.menu {
  // width: 60%;
  // height: 100%;
 float: right
}
// .menu ul {
//   // margin-top: 25px;
//   text-decoration: none;
//   font-family: "Montserrat" !important;
//   font-size: 16px;
//   font-weight: 500;
//   padding-top: 10px;
//   // color: #fff;
//   width: 100%;
// }

.menu ul {
  text-decoration: none;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  /* padding-top: 10px; */
  bottom: -10px !important;
  width: 100%;
  right: 60px;
}

.menu ul a {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 25px;
  text-decoration: none;
  color: white;
  display: inline-block;
}
.menu ul a:hover {
  // color: #fff;
  font-weight: bold;
  transition: 0.3s;
}
#menuToggle {
  display: none;
}
.menu-icon {
  display: none;
}

.inner-card {
  margin-left: 180px;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  pointer-events: auto;
  width: 200%;
  min-height: 50vh;
  margin-left: -250px;
}
.slide-text-section {
  position: relative;
  top: -54px;
  background-color: #fff;
  padding-bottom: 10px;
}
/*Zuhaib Changes*/
.itemContainer {
  height: fit-content;
  width: fit-content;
  padding: 2rem;
  height: 100%;
  margin: auto;
  background-color: #fff;
  h4 {
    font-size: 3rem;
  }
  p{
    font-size: 1.7rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }
  a{
    display: block;
    padding: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .Clock-days{
      font-weight: bold;
      font-size: 2rem;
  }
  .price{
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
  }
}
.leftImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.icon {
  width: 20rem;
  margin-bottom: 2rem;
}
.rightColumn {
  align-items: center;
  text-align-last: center;
}

.modalbutton{
  text-decoration: none;
  color: #101f3e;
  font-size: 12px;
  font-weight: bold;
}

.alreadyAccount{
  font-size: 12px;
  color: grey;
  text-align: center;
}


@media only screen and (min-width: 600px) {
  .itemContainer{  height: 37rem;}
  .leftImage {width: 33rem;}
}
.itemContainerDetail {
  height: auto;
  width: 600px;
  padding: 2rem;
  margin: auto;
  background-color: #fff;
  .itemContainerDescription {
    color: #303133;
  }
}
.rightColumnItemDetail {
  text-align: left;
}

.DynamicButton {
  display: block;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
}

.DynamicButton:hover {
  display: block;
  padding: 1rem; 
  opacity: 0.8;
  font-size: 1.7rem;
  font-weight: bold;
}
.DynamicText {
  font-size: 3rem;
  margin-top: 10px;
}
.DynamicVideo{
  max-width: 83%;
   margin-left: -5rem;
}
.DynamicAddCard{
  padding: 10rem;
}

.sold_button_check {
opacity: 0.6;
}

.marketplace__item {

  background-color: #fff;
  h4 {
    font-size: 3rem;
    margin-top: 10px;
  }
  p,
  .p {
    font-size: 2rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }
  a {
    display: block;
    padding: 1rem;
   
    font-weight: bold;
  }
  .Clock-days {
    font-weight: bold;
    font-size: 2rem;
  }
  .price {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
    line-height: 1.1;
  }
}

/*Zuhaib Changes End*/



/*Início das configurações da Navigation com dispositivos < 768px*/
@media screen and (min-width: 768px) {
  .slide-text-section {
    position: relative;
    top: 0;
    background-color: #fff;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 500px) {
 .notify-me {
  margin-top: 20rem !important;
}
 
}
@media screen and (max-width: 400px) {

/*Zuhaib Changes*/

.pageLayout__grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 360px));
  justify-content: center;
  column-gap: 35px;
  row-gap: 45px;
  background-color: #022163;
  padding: 5px;
  margin: -5px auto 0;
  max-width: 1200px;
}

.sold_button_check {
  opacity: 0.6;
  }
  
.marketplace__item {
  background-color: #fff;
  h4 {
    font-size: 2rem;
    margin-top: 10px;
  }
  p,
  .p {
    font-size: 2rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }
  a {
    display: block;
    padding: 1rem;
   
    font-weight: bold;
  }
  .Clock-days {
    font-weight: bold;
    font-size: 2rem;
  }
  .price {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
    line-height: 1.1;
  }
}


.DynamicVideo{
  max-width: 83%;
  margin-left: 0rem;
}
.DynamicButton {
  display: block;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
}
.DynamicText {
  font-size: 1.8rem;
  margin-top: 10px;
}
.DynamicAddCard{
  padding: 2rem;
}

.itemContainer {
  height: fit-content;
  width: fit-content;
  padding: 2rem;
  height: 100%;
  margin: auto;
  background-color: #fff;
  h4 {
    font-size: 3rem;
  }
  p{
    font-size: 1.7rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }
  a{
    display: block;
    padding: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .Clock-days{
      font-weight: bold;
      font-size: 2rem;
  }
  .price{
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
  }
}
.leftImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.icon {
  width: 20rem;
  margin-bottom: 2rem;
}
.rightColumn {
  align-items: center;
  text-align-last: center;
}


@media only screen and (min-width: 600px) {
  .itemContainer{  height: 37rem;}
  .leftImage {width: 33rem;}
}
.itemContainerDetail {
  height: auto;
  width: 100%;
  padding: 2rem;
  margin: auto;
  background-color: #fff;
  .itemContainerDescription {
    color: #303133;
  }
}
.rightColumnItemDetail {
  text-align: left;
}
/*Zuhaib Changes End*/


  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }

  .carousel-control-prev {
    margin-left: -25px;
    margin-bottom: 30px;
  }
  .carousel-control-next {
    margin-right: -25px;
    margin-bottom: 30px;
  }
  .carousel-indicators {
    margin: -30px;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }
  .inner-card {
    margin-top: -15px;
  }
  .slider-image {
    margin-bottom: 1.5rem !important;
  }
  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 180px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: -60px;
  }

  .logo-style {
    margin-top: -35px;
  }
  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 20vh;
    width: 100%;
  }
  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }
  .dropdown-content.lists{
    margin-left: 0px;
   }
  .dropdown-content a.bar {
    // color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    max-height: 55px;
  }
  .custom-select {
    width: 100%;
  }
  .dropdown-content a.bar:hover {
    // background-color: white;
    // color: white;
  }
  .menu {
    // width: 100%;
    height: auto;
  }

  .menu ul {
    
    display: block;
    max-height: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: revert;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #101f3e;
    margin-left: 0px;
  }
  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    top: 0;
    right: 0;
    margin-top: 20px;
    line-height: 60px;
  }
  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }
  .menu-icon i {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 768px) {

  /*Zuhaib Changes*/

  .mobile-text{

font-size: 14px !important;

  }

  .play_button {
    font-size: 50px;
    position: absolute;
    color: white;
    left: 46%;
    top: 22%;
    z-index: 1200;
}

.mute_button {
  font-size: 24px;
  position: absolute;
  top: 74%;
  left: 11%;
  color: white !important;
  z-index: 1200;
  cursor: pointer;
}


  .video_main_page {
    max-width: 84%;
    margin-top: -8%;
    margin-bottom: 5%;
    margin-left: 0px;
}
  .featured_video_adjustment{
    position: relative;
      top: 0;
    }
  

  .modalbutton{
    text-decoration: none;
    color: #101f3e;
    font-size: 12px;
    font-weight: bold;
  }

  .alreadyAccount{
    font-size: 12px;
    color: grey;
    text-align: center;
  }

  .sold_button_check {
    opacity: 0.6;
    }
    
  .marketplace__item {
    background-color: #fff;
    h4 {
      font-size: 2rem;
      margin-top: 10px;
    }
    p,
    .p {
      font-size: 2rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }
    a {
      display: block;
      padding: 1rem;
     
      font-weight: bold;
    }
    .Clock-days {
      font-weight: bold;
      font-size: 2rem;
    }
    .price {
      font-size: 2rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
    }
  }
  
  .DynamicVideo{
    max-width: 100%;
    margin-left: 0rem;
  }
  .DynamicText {
    font-size: 1.8rem;
    margin-top: 10px;
  }
  .DynamicAddCard{
    padding: 2rem;
  }
  
  .itemContainer {
    height: fit-content;
    width: fit-content;
    padding: 2rem;
    height: 100%;
    margin: auto;
    background-color: #fff;
    h4 {
      font-size: 3rem;
    }
    p{
      font-size: 1.7rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }
    a{
      display: block;
      padding: 1rem;
      font-size: 2rem;
      font-weight: bold;
    }
    .Clock-days{
        font-weight: bold;
        font-size: 2rem;
    }
    .price{
        font-size: 2.5rem;
        font-weight: bold;
        margin-top: 10px;
        line-height: 1.1;
    }
  }
  .leftImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    width: 20rem;
    margin-bottom: 2rem;
  }
  .rightColumn {
    align-items: center;
    text-align-last: center;
  }
  
  
  @media only screen and (min-width: 600px) {
    .itemContainer{  height: 37rem;}
    .leftImage {width: 33rem;}
  }
  .itemContainerDetail {
    height: auto;
    width: 100%;
    padding: 2rem;
    margin: auto;
    background-color: #fff;
    .itemContainerDescription {
      color: #303133;
    }
  }
  .rightColumnItemDetail {
    text-align: left;
  }
  /*Zuhaib Changes End*/
  
  
    .ant-modal-content {
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 4px;
      pointer-events: auto;
      width: 90%;
      // min-height: 10vh;
      margin-left: 20px;
    }
  
    .carousel-control-prev {
      margin-left: -25px;
      margin-bottom: 30px;
    }
    .carousel-control-next {
      margin-right: -25px;
      margin-bottom: 30px;
    }
    .carousel-indicators {
      margin: -30px;
    }
  
    .loading-spinner-initial {
      text-align: center;
      margin-top: -100px;
    }
    .inner-card {
      margin-top: -15px;
    }
    .slider-image {
      margin-bottom: 1.5rem !important;
    }
    .inner-card-body {
      margin-top: -5px;
      padding-bottom: 180px;
      box-sizing: content-box;
      width: 140%;
      border-radius: 50%;
      margin-left: -60px;
    }
  
    .logo-style {
      margin-top: -35px;
    }
    .dropdown {
      width: 100%;
    }
  
    .dropdown:hover {
      height: 20vh;
      width: 100%;
    }
    .dropdown-content {
      margin: revert;
      margin-bottom: 10px;
    }
    .dropdown-content.lists{
      margin-left: 0px;
     }
    .dropdown-content a.bar {
      // color: white;
      text-decoration: none;
      display: block;
      border-bottom: white 1px solid;
      max-height: 55px;
    }
    .custom-select {
      width: 100%;
    }
    .dropdown-content a.bar:hover {
      // background-color: white;
      // color: white;
    }
    .menu {
      width: 100%;
      height: auto;
    }
  
    .menu ul {
      
      display: block;
      max-height: 0;
      width: 100%;
      overflow-x: hidden;
      -webkit-transition: max-height 0.3s;
      -moz-transition: max-height 0.3s;
      -ms-transition: max-height 0.3s;
      -o-transition: max-height 0.3s;
      transition: max-height 0.3s;
    }
    .menu ul a {
      text-align: center;
      width: 100%;
      height: auto;
      margin: revert;
      text-decoration: none;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      background-color: #101f3e;
      margin-left: 0px;
    }
    // .menu-icon {
    //   width: 100px;
    //   height: inherit;
    //   font-size: 16px;
    //   display: block;
    //   position: absolute;
    //   color: White;
    //   top: 0;
    //   right: 0;
    //   margin-top: 20px;
    //   line-height: 60px;
    // }

    .menu-icon {
      width: 100px;
      height: inherit;
      font-size: 16px;
      display: block;
      position: absolute;
      color: White;
      top: -87px;
      right: 0;
      line-height: 60px;
  }
    #menuToggle:checked ~ ul {
      max-height: 350px;
      /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
    }
    .menu-icon i {
      font-size: 1.7em;
    }
  }

@media screen and (max-width: 1024px) {

  
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }

  .carousel-control-prev {
    margin-left: -25px;
    margin-bottom: 30px;
  }
  .carousel-control-next {
    margin-right: -25px;
    margin-bottom: 30px;
  }
  .carousel-indicators {
    margin: -30px;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }
  .inner-card {
    margin-top: -15px;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 180px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: -60px;
  }
  .logo-style {
    margin-top: -35px;
  }
  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 20vh;
    width: 100%;
  }
  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }
  .dropdown-content.lists{
    margin-left: 0px;
   }
  .dropdown-content a.bar {
    // color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    width: 42%;
    // height: 8vh;
  }
  .custom-select {
    width: 100%;
  }

  .dropdown-content a.bar:hover {
    // background-color: white;
    color: white;
  }
  .menu {
    width: 100%;
    height: auto;
  }

  .menu ul {
    // margin-top: 14px;
    display: block;
    max-height: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #101f3e;
    margin-left: 0px;
  }
  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    top: 0;
    right: 0;
    margin-top: 20px;
    line-height: 60px;
  }
  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }
  .menu-icon i {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 1330px) {



  /*Zuhaib Changes*/

  /*Zuhaib Changes End*/
  
  /*Basit*/
  .auction-detail-page {
    height: auto !important;
    margin: 0;
  }
  .auto-box-new {
    margin: 20px !important;
  }
  .carousel-arrow-slide {
    margin: 3rem !important;
  }
  /*end*/
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }

  .carousel-control-prev {
    margin-left: 0;
    margin-bottom: 30px;
  }
  .carousel-control-next {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .carousel-indicators {
    margin: 0;
    position: relative;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }
  .inner-card {
    margin-top: -15px;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 192px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: 0;
    height: 490px !important;
  }
  .logo-style {
    margin-top: -18px;
  }
  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 20vh;
    width: 100%;
  }
  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }
  .dropdown-content.lists{
    margin-left: 0px;
   }
  .dropdown-content a.bar {
    color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    width: 42%;
    // height: 16vh;
  }
  .custom-select {
    width: 100%;
  }

  .dropdown-content a.bar:hover {
    // background-color: white;
    color: white;
  }
  .menu {
    width: 100%;
    height: auto;
  }

  .menu ul {
    // margin-top: 14px;
    display: block;
    max-height: 0;
    position: relative;
    top: 3px;
    width: 100%;
    left: 0px;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #101f3e;
    margin-left: 0px;
  }
  header {
    height: 100px;
    background: #101f3e;
    width: 100%;
    z-index: 10;
    position: relative;
    line-height: 50px;
    text-align: center;
  }
  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    // bottom: 4px;
    right: 0;
    top: 10px !important;
    // margin-top: 20px;
    line-height: 60px;
  }
  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }
  .menu-icon i {
    font-size: 1.7em;
  }
  .justify-content-end {
    height: 300px;
  }
  .brand {
    width: 60px !important;
  }

  // .img-box {
  //   height: 230px !important;
  //   margin-left: 0 !important;
  //   overflow: hidden;
  // }
  // .img-box-row {
  //   width: 340px !important;
  //   height: 210px !important;
  // }
  .img-box-col-12 {
    overflow: hidden !important;
  }
  .img-box img {
    margin-left: 0px !important;
    height: 100% !important;
  }
  .border-right {
    padding-right: 0 !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }
  .borders {
    border-top: 0px !important;
    border-bottom: 0px !important;
  }
  .border-image {
    border: 2px solid #ddd !important;
    padding: 0px !important;
  }
  .profile-btn-last {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}