.pageLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  background-color: black; // like figma mockup
  color: white; // like figma mockup
  text-align: center;
}
.general__padding {
  padding: 50px 25px 100px
}
.link-preview {
  width:100%
}
.pageLayout__grid-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  row-gap: 20px;
  background-color: #022163;
  margin: -5px auto 0;
  max-width: 1200px;
  padding: 5px;
  margin-bottom: 20px;
}

.pageLayout__title {
  font-size: 25px;
  color: white;
  margin: 50px 0 30px 0;
}

.item__image {
  height: 100%;
  object-fit: cover;
}
@media (max-width: '400px') {
  .pageLayout__grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 360px));
    justify-content: center;
    column-gap: 35px;
    row-gap: 45px;
    background-color: #022163;
    padding: 5px;
    margin: -5px auto 0;
    max-width: 1200px;
  }
  .item__video {
    // height: 400px;
    object-fit: cover;
  }
}

.pageLayout__container {
  padding: 25px;
  margin: 25px auto 0;
  max-width: 1200px;
}

.item__container {
  padding: 5px;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

@media (min-width: 780px) {
  .general__padding {
    padding: 0px 0px 100px;
  }
  .link-preview {
    width:50%
  }
  .pageLayout__grid-container {
    padding: 25px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 360px));
    justify-content: center;
    column-gap: 35px;
    row-gap: 45px;
  }
}
