$blue: #4052de !default;
$indigo: #5367fa !default;
$pink: #ec6180 !default;
$red: #ff5c47 !default;
$yellow: #feb734 !default;
$green: #05d68f !default;
$cyan: #1989fa !default;
$offblue: #F2F6F9 !default;
$border-color: #ebeef5 !default;
$border-color-custom: #ebedf1 !default;
$light: #ebedf1 !default;
$extralight:#F7F7F7 !default;
$lightblue: #E9EBFB !default;
$body-color: #303133;
// START: BRAND COLORS
// $primary: #70BCFD;
$primary: #0172E2;
$success: #04DEB4;
$info: #00B1FF;
$warning: #FFAE57;
$danger: #FF766B;
$gray-dark: #000000;

$page_bg: white;
$light-blue: #00B1FF;
$blue: #1A457C;
$dark: #505050;
$grey: #303133;
$link: #0076ff;
$green: #ACD503;



// END: BRAND COLORS
$line-height-base: 1.7142857 !default; // 24/14


$component-active-bg: $info;


//Card Border
$card-border-radius: 0.8rem;


// border
$border-radius: 0.475rem !default;
$body-bg: $offblue;

$list-group-border-radius: 0;

$breadcrumb-bg: transparent !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-padding-y: 0 !default;

$table-filter-bg: #f8f8f8;

$font-size-base: 0.875rem !default;

// Badges
$badge-font-size: 75% !default;
$badge-font-weight: 400 !default;
$badge-padding-y: .35em !default;
$badge-padding-x: .5em !default;
// $badge-border-radius: $border-radius !default;

$nav-tabs-border-color: transparent !default;
$nav-tabs-border-width: 2px !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $primary !default;
$nav-tabs-link-active-bg: transparent !default;
$nav-tabs-link-active-border-color: transparent !default;


//link
$link-color: #4052DE;
$link-hover-color: darken(#4052DE, 15%);

$hr-border: rgba(0, 0, 0, .05);

$form-group-margin-bottom: 2.6rem;

$progress-bg: $success;

$pagination-color: $blue;
$pagination-active-bg: $blue;

$tooltip-max-width: 300px;

$tooltip-color: $body-color;
$tooltip-bg: #ffffff;
$tooltip-opacity: 1;

$grid-gutter-width: 15px;
