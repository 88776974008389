@import "./_variables.scss";
@import "./bootstrap/scss/bootstrap.scss";
@import "./fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

@import "./light.scss";
@import "./fontawesome.scss";
@import "./blueimp-gallery.min.scss";
@import "./_base.scss";
@import "./_left_menu.scss";
@import "./_theme.scss";
@import "./design.scss";
@import "./_utilities.scss";
// @import "./itemCard.scss";
@import "./platform.scss";
// @import "_itemDetailCard.scss";
@import "./Marketplace.scss";
@import "./MyPortfolio.scss";
// @import "./newdashboard.scss";
// @import "_itemDetailCard.scss";
  
p,
h2,
h1,h2,
h3,h4,h5,h6,a,span,div {
  font-family: "Montserrat" !important;
  font-style: normal;
}
.loading-spinner-initial {
  text-transform: capitalize;
  animation: brath 4s linear infinite;
}
@keyframes brath {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
