.nav-container {
  position: relative;

  .aside-toggler {
    padding: 7px 5px;
    border-radius: 50%;
    font-size: 1.2rem;
    background-color: $blue;
    color: $white;
    position: fixed;
    left: 183px;
    top: 60px;
    width: 32px;
    height: 32px;
    text-align: center;
    z-index: 9;

    .fa {
      margin-top: 4px;
    }
  }
}

.left-navbar {
  background-color: #9b59b6;
  background: linear-gradient(#9b59b6 0%, #9b59b6 100%);
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 200px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: auto;
  display: block;
  // transition: all .4s ease;
  z-index: 2;

  @media (min-width: 768px) {
    &::-webkit-scrollbar {
      width: 6px;
      /* for vertical scrollbars */
      height: 8px;
      /* for horizontal scrollbars */
    }
  }

  .navbar-brand {
    padding-top: 10px;
    padding-bottom: 20px;

    @include media-breakpoint-down(xs) {
      padding-top: 65px;
    }
  }

  .nav-pills {
    .nav-link {
      color: $white;
      font-size: 14px;

      i {
        padding-right: 12px;
      }

      span {
        white-space: nowrap;
      }
    }

    .nav-link:hover {
      background-color: rgba($white, 0.3);
      border-radius: $border-radius;
    }

    .nav-item {
      width: 100%;
      padding-bottom: 15px;
    }

    .active {
      background-color: rgba($white, 0.3);
    }
  }
}


.secondary-menu {
  background-color: $white;
  left: 200px;
  position: fixed;
  top: 67px;
  bottom: 0;
  width: 100%;
  max-width: 220px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  overflow-y: auto;
  // overflow: visible;

  .nav-link {
    color: $dark;
    white-space: nowrap;
    font-weight: 500;

    &:hover {
      color: $primary;
    }

    &.active {
      font-weight: bold;
      color: $primary;
    }
  }


  .nav-link[data-toggle].collapsed:after {
    content: "\f107";
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    font-size: 1.33333em;
    float: right;
  }

  .nav-link[data-toggle]:not(.collapsed):after {
    content: "\f106";
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    font-size: 1.33333em;
    float: right;
  }
}

.nav-minimize {
  .nav-container {
    .aside-toggler {
      left: 63px;
    }
  }

  .logo {
    display: none;
  }


  .logo-emblem {
    display: block;
  }

  .left-navbar {
    max-width: 80px;

    @include media-breakpoint-down(xs) {
      transform: translate(-100%, 0);
    }

    .navbar-brand {
      margin: 0;
    }

    .nav-pills {
      .nav-link {
        // font-size: 20px;
        text-align: center;
        padding: 1rem 1.8rem;
        justify-content: center;
        font-size: 1.8rem;
        margin: 0;

        span {
          display: none;
        }

        i {
          padding-right: 0;
        }
      }

    }
  }

  .main-content {
    padding: 10px;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }
  }

  .secondary-menu {
    left: 80px;
  }
}

.left-sub-menu-padding {
  padding-left: 240px;
}
